.mat-header {
  background: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: auto;
  line-height: normal;
  padding: 8px 15px;
}

// .mat_header_page_title {
//   text-align: center;
//   margin-left: 8px;
//   max-width: 700px;
//   font-size: 14px;
//   color: var(--black);
//   font-weight: 400;
//   white-space: nowrap;
//   margin-right: 10px;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   width: 100%;
//   text-align: left;
// }

.mat-header-selects-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 80%;
  .mat-select-wrapper {
    max-width: 200px;
    &:first-child {
      margin-right: 5px;
      .ant-select-selection-search {
        color: var(--mainColor);
        display: block;
      }
    }
    &:last-child {
      margin-left: 5px;
    }
    .mat-select-sub-wrapper {
      height: 32px;
      .mat-select {
        height: 32px;
      }
    }
    &.white {
      &:first-child {
        .ant-select-selection-search {
          input {
            color: var(--white);
          }
        }
      }
    }
  }
}

.mat_layout_content_for_extension {
  .mat-header-selects-wrapper {
    .mat-select-wrapper:first-child {
      margin-right: 0;
      width: 50%;
      max-width: 100%;
    }
    .mat-select-wrapper {
      max-width: 100%;
      width: 50%;
    }
  }
  // .mat_header_page_title {
  //   margin-left: 0;
  //   display: none;
  // }
}

.header_mobile_sidebar.mat-dropdown-trigger-container {
  max-width: max-content;
  &.ant-dropdown-open {
    z-index: 10000;
    transform: rotate(180deg);
  }
  &.ant-dropdown-open {
    span {
      svg {
        path {
          fill: var(--white);
        }
      }
    }
  }
}

.location-without-select {
  white-space: nowrap;
  width: 14%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--black);
}

.mat-header-selects-wrapper
  .mat-select.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 32px;
  padding-left: 28px;
}

.header_mobile_sidebar_dropdown {
  position: absolute;
  left: 0 !important;
  top: 0 !important;
  padding: 40px 16px 16px;
  background: transparent;
  box-shadow: none;
  background-color: var(--purple);
  max-width: 230px;
  width: 100% !important;
  max-height: 260px;
  border-radius: 0 12px 12px 0;
  .ant-dropdown-menu {
    border-radius: 0;
    background-color: var(--purple);
    padding: 0;
    box-shadow: none;
    margin-top: 18px;
    display: grid;
    gap: 16px;
    .ant-dropdown-menu-item:last-child {
      border-top: 1px solid #48427d;
      border-radius: 0;
      padding-top: 16px;
    }
    .drop-item {
      padding: 9px 12px;
      &.active {
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 12px;
      }
      .drop-item-icon-with-text {
        color: var(--white);
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .mat-header-selects-wrapper {
    .mat-select-wrapper {
      &:last-child {
        max-width: 32px;
        .mat-select-sub-wrapper.select-with-icon {
          .select-left-icon {
            border-right: none;
          }
        }
      }
      &:first-child {
        max-width: 170px;
        min-width: 160px;
      }
    }
  }

  .mat-header-selects-wrapper
    .mat-select-wrapper:last-child
    .mat-select-sub-wrapper
    .mat-select
    .ant-select-selection-item,
  .mat-header-selects-wrapper
    .mat-select-wrapper:last-child
    .mat-select-sub-wrapper
    .mat-select
    .ant-select-arrow {
    opacity: 0;
    transition: none !important;
    line-height: 32px !important;
  }

  .mat_layout_content_for_extension
    .mat-header-selects-wrapper
    .mat-select-wrapper
    .mat-select-sub-wrapper
    .mat-select
    .ant-select-selection-item,
  .mat_layout_content_for_extension
    .mat-header-selects-wrapper
    .mat-select-wrapper
    .mat-select-sub-wrapper
    .mat-select
    .ant-select-arrow {
    opacity: 1;
  }

  .mat-header-selects-wrapper
    .mat-select.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    padding-left: 29px;
  }

  .mat-header-dropdown-responsive-first {
    /* I gave !important because the menu style gets inline and this is the only way to change the style */
    left: auto !important;
    right: 0px;
  }

  .mat-header-dropdown-responsive-second {
    /* I gave !important because the menu style gets inline and this is the only way to change the style */
    left: auto !important;
    right: 0px !important;
  }

  .mat_layout_content_for_extension
    .mat-header-selects-wrapper
    .mat-select-wrapper:last-child
    .mat-select-sub-wrapper.select-with-icon
    .select-left-icon {
    padding-right: 8px;
    left: 8px;
    border-right: 1px solid rgba(91, 102, 234, 0.2);
    transform: none !important;
  }

  .mat_layout_content_for_extension
    .mat-header-selects-wrapper
    .mat-select-wrapper
    .mat-select-sub-wrapper
    .mat-select {
    opacity: 1;
  }

  .mat_layout_content_for_extension
    .mat-header-selects-wrapper
    .mat-select-wrapper
    .mat-select-sub-wrapper
    .mat-select
    .ant-select-selector {
    padding: 0 17px 0 40px !important;
  }

  .mat_layout_content_for_extension
    .mat-header-selects-wrapper
    .mat-select-wrapper:first-child
    .mat-select-sub-wrapper
    .mat-select
    .ant-select-selector {
    cursor: text;
  }

  .mat_layout_content_for_extension
    .mat-header-selects-wrapper
    .mat-select-wrapper:first-child
    .mat-select-sub-wrapper
    .mat-select
    .ant-select-selector
    .ant-select-selection-search-input {
    padding-left: 28px;
    opacity: 1;
  }

  .mat-header-selects-wrapper
    .mat-select-wrapper:last-child
    .mat-select-sub-wrapper.select-with-icon
    .select-left-icon {
    left: 50%;
    transform: translateX(-50%);
    padding: 0;
  }

  .mat_layout_content_for_extension {
    .mat-header-selects-wrapper {
      .mat-select-wrapper {
        max-width: 100%;
      }
    }
  }
}

@media screen and (max-width: 479px) {
  // .mat_header_page_title {
  //   font-size: 16px;
  // }
}

.my_default_breadcrumb {
  width: 100%;
  .ant-breadcrumb-separator {
    display: flex;
  }
  .ant-breadcrumb-link {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: var(--thridyTextColor);
  }
  li:last-child {
    .ant-breadcrumb-link {
      color: var(--black);
    }
  }
}

body {
  &:has(.my_default_page_layout) {
    overflow: hidden;
  }
}
.my_default_page_layout {
  padding: 24px 32px;
  background-color: var(--newBackgroundGrey);
  height: calc(var(--app-height) - 48px); // viewport - (header + footer)
  overflow-y: auto;
  width: 100%;
  &.center {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

.mat_layout {
  &:has(.mat-footer):has(.my_default_page_layout) {
    .my_default_page_layout {
      height: calc(var(--app-height) - 117px);
    }
  }
}

@media screen and (max-width: 991px) {
  .my_default_page_layout {
    padding: 24px 20px;
  }
}

@media screen and (max-width: 767px) {
  .mat_layout {
    &:has(.mat-footer):has(.my_default_page_layout) {
      .my_default_page_layout {
        height: calc(var(--app-height) - 157px);
      }
    }
  }
}

.header_toggle_icon_container {
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%;
  transition: all 0.3s ease-in-out;
  margin-right: 10px;
  &.icon_rotate {
    transform: rotate(180deg);
  }
}
